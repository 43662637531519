import createClient from 'openapi-fetch';
import { getRequestEvent, isServer } from 'solid-js/web';
import setCookie from 'set-cookie-parser';
import type { paths } from './__generated__/database.ts';
import type { Client, ClientOptions } from 'openapi-fetch';

const clientOptions: ClientOptions = {
	baseUrl: '/',
	credentials: 'include',
	mode: 'cors',
	referrerPolicy: 'origin',
	keepalive: true,
};

export type ApiClient = Client<paths, `${string}/${string}`> & {
	isAuthenticated?: boolean;
	setCookies?: Array<setCookie.Cookie>;
};

export function createServerApiClient(config: ClientOptions, inputHeaders?: Record<string, string>): ApiClient {
	if (!isServer) {
		throw new Error('Cannot create server API client in the browser.');
	}

	const client: ApiClient = createClient<paths>({
		...clientOptions,
		...config,
		headers: inputHeaders,
		fetch: globalThis.fetch,
	});
	client.use({
		async onResponse({ response: res }) {
			const cookies = setCookie.parse(res.headers.getSetCookie(), { decodeValues: true });
			(client as ApiClient).setCookies = cookies;
			return res;
		},
	});
	client.isAuthenticated = false;
	return client as ApiClient;
}

export function createApiClient(config?: ClientOptions) {
	// @ts-ignore
	globalThis.__trBrowserClient = createClient<paths>({ ...clientOptions, fetch: globalThis.fetch, ...config });
	// @ts-ignore
	globalThis.__trBrowserClient.isAuthenticated = false;
	return getBrowserClient();
}

export function getApiClient(config?: ClientOptions): ApiClient {
	if (isServer) {
		// @ts-expect-error They forgot about this in the upstream defs…
		return getRequestEvent()!.locals.apiClient;
	}

	const client = getBrowserClient();
	if (!client) {
		return createApiClient(config);
	}
	return client;
}

function getBrowserClient() {
	// @ts-ignore
	return globalThis.__trBrowserClient as ApiClient;
}
